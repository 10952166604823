<template>
  <div>
    <form v-show="!loadingForm" action="#" @submit.prevent novalidate>
      <div
        v-if="route.path !== '/partner/create'"
        class="partner-relations flex align-item-start"
      >
        <div class="flex flex-column justify-content-center info-relation">
          <router-link
            :to="`/experts?id=${currentId}`"
            :class="`btn ${
              expertsChanges && getRight('partnersList')
                ? 'btn-accent'
                : 'btn-w'
            }`"
            >Перейти к экспертам</router-link
          >
          <span v-if="expertsChanges && getRight('partnersList')"
            >Есть изменения</span
          >
        </div>
        <router-link :to="`/reviews?id=${currentId}`" class="btn btn-w"
          >Перейти к отзывам</router-link
        >
      </div>
      <div v-if="route.path === '/partner/create'" class="create-partner flex">
        <div class="col">
          <custom-select-2
            name="Пользователь"
            label="Пользователь*"
            v-model="createData.user_id"
            :settings="usersAjax"
          />
        </div>
      </div>
      <div class="inputs display--flex justify-content-center">
        <div class="col">
          <preview-file-loader
            @loadPhoto="
              (data) => {
                form.image = data;
                form.currentImage = null;
              }
            "
            @delete="
              () => {
                form.currentImage = null;
                form.image = null;
              }
            "
            :image="form.currentImage ? form.currentImage : form.image"
            class="photo-area"
            label="Логотип"
            name="image"
          />
          <custom-input
            v-model="form.title"
            label="Название*"
            name="title"
            req
          />
          <custom-textarea
            name="short_description"
            label="Краткое описание"
            horizontal
            v-model="form.short_description"
            :maxLength="250"
            :height="100"
          />

          <div class="format-checkboxes flex align-item-center block">
            <div class="format-title block-title">Формат*</div>
            <div class="flex checkboxes">
              <custom-checkbox
                name="format1"
                v-model="format.online"
                label="Онлайн"
                :checked="format.online"
              />
              <custom-checkbox
                name="format2"
                v-model="format.offline"
                label="Офлайн"
                :checked="format.offline"
              />
              <custom-checkbox
                name="format3"
                v-model="format.trip"
                label="Командировка"
                :checked="format.trip"
              />
            </div>
          </div>
          <custom-select-2
            name="services"
            :multiple="true"
            label="Услуги*"
            :options="servicesOptions"
            v-model="form.services"
          />
        </div>
        <div class="col">
          <city-input
            :city="form.city"
            label="Город*"
            @changeCity="(cityId) => (form.city_id = cityId)"
          />
          <div class="editor-block block">
            <div class="editor-title block-title">Описание</div>

          </div>
        </div>
      </div>
      <div class="btns mt-25 mb-10 display--flex justify-content-end">
        <button
          v-if="
            getRight('partnersList') && partner && partner.status !== 'approved'
          "
          type="button"
          @click="submitPartner('approved')"
          class="btn btn-accent mr-10"
        >
          Одобрить
        </button>
        <button
          type="button"
          @click="submitPartner(false)"
          class="btn btn-accent mr-10"
        >
          Сохранить
        </button>
        <button type="button" @click="router.go(-1)" class="btn btn-w">
          Назад
        </button>
      </div>
      <div class="form-preloader" v-if="transactionLoading">
        <preloader />
      </div>
    </form>
    <div class="table-preloader" v-show="loadingForm">
      <preloader />
    </div>
  </div>
</template>

<script>
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import { useStore } from "vuex";
import { reactive, ref, computed } from "@vue/reactivity";
import PreviewFileLoader from "@/components/Forms/Fields/PreviewFileLoader.vue";
import { onBeforeMount, watch } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import standartError from "@/mixins/standartError";
import CustomRadio from "@/components/Forms/Fields/CustomRadio.vue";
import useErrors from "@/mixins/useErrors";
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";
import CustomTextarea from "../../../components/Forms/Fields/CustomTextarea.vue";
import CityInput from "../../../components/Forms/Fields/CityInput.vue";
import CustomSelect2 from "../../../components/Forms/Fields/CustomSelect2.vue";
import MultipleImageInput from "../../../components/Forms/Fields/MultipleImageInput.vue";
import CustomSelect from "../../../components/Forms/Fields/CustomSelect.vue";
import useAjaxSelect from "@/mixins/useAjaxSelect";
import useCheckRights from "../../../mixins/useCheckRights";
import Preloader from "../../../components/Technical/Preloader.vue";

export default {
  components: {
    CustomInput,
    PreviewFileLoader,
    CustomRadio,
    CustomSelect2,
    CustomCheckbox,
    CustomTextarea,
    CityInput,
    MultipleImageInput,
    CustomSelect,
    Preloader,
  },
  name: "center-form",
  setup() {
    const store = useStore(),
      router = useRouter(),
      route = useRoute(),
      { setErrors, clearCustomErrors } = useErrors();


    const services = computed(() => store.state.partners.services);
    const partner = computed(() => store.state.partners.partner);
    const currentPartner = computed(() => store.state.partners.currentPartner);
    const expertsChanges = computed(() =>
      form.experts
        ? form.experts.find(
            (expert) =>
              expert.status === "new" || expert.status === "need_apprpve"
          )
        : -1
    );
    const currentId = computed(() =>
      route.params.id ? route.params.id : currentPartner.value?.id
    );
    const loadingForm = computed(() => store.state.ui.loadingForm);
    const transactionLoading = computed(
      () => store.state.ui.transactionLoading
    );
    const servicesOptions = ref([]);
    const { getRight } = useCheckRights();
    const { usersAjax } = useAjaxSelect();
    const form = reactive({
      title: null,
      city: null,
      city_id: null,
      image: null,
      currentImage: null,
      short_description: null,
      description: null,
      services: null,
      experts: null,
    });
    const createData = reactive({
      user_id: null,
    });
    const format = reactive({
      online: false,
      offline: false,
      trip: false,
    });

    onBeforeMount(() => {
      store.dispatch("partners/getServices").then(() => {
        servicesOptions.value = Object.entries(services.value).map(
          ([key, value]) => ({ id: key, text: value })
        );
      });
      if (currentId.value) {
        getPartnerWithLoading();
      }
    });

    const getPartnerWithLoading = () => {
      store.commit("ui/setLoadingForm", true);
      store
        .dispatch("partners/getPartner", currentId.value)
        .then(() => {
          setPartner(partner);
        })
        .finally(() => store.commit("ui/setLoadingForm", false));
    };

    const setPartner = (partner) => {
      const {
        description,
        short_description,
        title,
        job_formats,
        services,
        city,
        experts,
        image,
      } = partner.value;
      form.title = title;
      form.short_description = short_description;
      job_formats.forEach((el) => {
        format[el] = true;
      });
      form.services = services;
      form.currentImage = image;
      form.city = city ? city.name : null;
      form.city_id = city ? city.id : null;
      form.experts = experts;
    };

    const submitPartner = (approved) => {
      store.commit("ui/setTransactionLoading", true);
      if (route.path === "/partner/create") {
        store
          .dispatch("partners/createPartner", {
            ...form,
            user_id: Number(createData.user_id),
            job_formats: Object.entries(format)
              .filter(([key, value]) => value)
              .map(([key, value]) => {
                return key;
              }),
          })
          .then(() => {
            router.push("/partners");
          })
          .catch(standartError(setErrors, clearCustomErrors))
          .finally(() => store.commit("ui/setTransactionLoading", false));
        return;
      }
      store
        .dispatch("partners/updatePartner", {
          id: currentId.value,
          partner: {
            ...form,
            status: approved || partner.value.status,
            user_id:
              currentPartner.value && currentPartner.value.id
                ? currentPartner.value.user_id
                : partner.value.user_id,
            job_formats: Object.entries(format)
              .filter(([key, value]) => value)
              .map(([key, value]) => {
                return key;
              }),
          },
        })
        .catch(standartError(setErrors, clearCustomErrors))
        .finally(() => store.commit("ui/setTransactionLoading", false));
    };

    watch(currentPartner, () => {
      if (currentPartner.value?.id) {
        getPartnerWithLoading();
      }
    });

    return {
      form,
      submitPartner,
      router,
      format,
      route,
      createData,
      usersAjax,
      servicesOptions,
      partner,
      currentPartner,
      currentId,
      loadingForm,
      transactionLoading,

      getRight,

      expertsChanges,
    };
  },
};
</script>

<style lang="scss">
.editor-block {
  .ql-editor {
    font-size: 15px;
    height: 200px;
  }
}
</style>
<style lang="scss" scoped>
.partner-relations {
  gap: 15px;
  .info-relation {
    span {
      text-align: center;
      font-size: 14px;
      margin-top: 3px;
      color: var(--main-color);
    }
  }
}
.create-partner {
  border-bottom: 1px solid var(--cream-color);
}

.format-checkboxes {
  padding-left: 10px;
  margin-top: 15px;
  .format-title {
    flex-basis: 25%;
  }

  .checkboxes {
    gap: 28px;
  }

  @media (max-width: 973px) {
    padding-left: 0;
    flex-direction: column;
    align-items: flex-start;
    .checkboxes {
      padding-left: 7px;
    }
  }

  @media (max-width: 816px) {
    .checkboxes {
      flex-direction: column;
      gap: 4px;
      margin-top: 10px;

      .form-group {
        margin: 5px -5px;
      }
    }
  }
}

.block {
  padding-left: 10px;
  .block-title {
    font-size: 15px;
    font-weight: 400;
  }
  @media (max-width: 973px) {
    padding-left: 0;
  }
}

.editor-title {
  margin-bottom: 15px;
}

.col {
  flex: 0 1 50%;
  padding: 0 10px;
}

@media (max-width: 1500px) {
  .inputs,
  .create-partner {
    flex-direction: column;
  }
}

@media (min-width: 769px) {
  .form-group {
    margin: 25px -5px;
  }
}

@media (max-width: 425px) {
  .photo-area {
    margin: 0 !important;
  }
}
</style>